.content {
  font-family: "Courier New", Courier, monospace;
  display: flex;
  justify-content: top;
  padding-top: 10vh;
  align-items: center;
  text-align: center;
  flex-direction: column;
  background-color: rgb(29, 31, 36);
  min-height: 100vh;
  position: relative;
}

.main {
  position: relative;
}

.tjd-link {
  position: fixed;
  top: 1rem;
  left: 2rem;
  z-index: 100;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 10rem;
}

.tjd-link .link:hover {
  cursor: pointer;
  text-decoration: underline;
  color: rgb(90, 140, 250);
}

h1 {
  font-size: 3rem;
  color: rgb(9, 182, 101);
}

input {
  font-size: x-large;
  width: 100%;
  height: 3rem;
  border: none;
  padding: 0 1rem;
}

input:focus {
  outline: none;
}

.words {
  background-color: rgb(15, 15, 15);
  color: whitesmoke;
  font-family: "Courier New", Courier, monospace;
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: nowrap;
  overflow-x: hidden;
  width: 900px;
  border-radius: 10px;
  margin: 1.5rem;
  padding: 0 0.5rem;
  box-shadow: 2px 2px 10px #000000;
  position: relative;
}

.ok {
  box-shadow: 1px 1px 8px rgb(9, 182, 101);
}

.error {
  box-shadow: 1px 1px 8px rgb(228, 39, 39);
}

.time {
  background-color: rgb(228, 39, 39);
  color: white;
  font-size: 1.3rem;
  font-weight: normal;
  padding: 0 1.25rem;
  width: 2rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px 0 0 10px;
}

p {
  padding: 0.25em 0.5em;
  font-size: x-large;
  user-select: none;
}

.current-incorrect {
  background-color: rgba(255, 0, 0, 0.3);
}
.current-correct {
  background-color: rgba(0, 128, 0, 0.2);
}

.correct {
  background-color: rgb(15, 15, 15);
  color: green;
  text-shadow: 0px 0px 9px rgb(9, 182, 101);
}

.incorrect {
  background-color: rgb(15, 15, 15);
  color: red;
  text-shadow: 0px 0px 9px rgb(228, 39, 39);
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 1.5rem 0;
  border-radius: 0 5px 5px 0;
  background-color: rgb(53, 111, 177);
  color: black;
  cursor: pointer;
  font-size: x-large;
  border: none;
  height: 3rem;
  padding: 0 1rem;
  width: 150px;
}

button:hover {
  background-color: rgb(97, 155, 221);
}

.input-button-container {
  width: 900px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.done {
  background-color: whitesmoke;
  border-radius: 10px;
  padding: 0 1.5rem;
}

.key-code {
  font-size: 0.7em;
  padding-right: 0.75rem;
  color: whitesmoke;
}

table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

thead {
  background-color: rgb(15, 15, 15);
  color: whitesmoke;
}

.log {
  margin-top: 4rem;
  width: 900px;
  background-color: whitesmoke;
}

h1.title {
  font-size: 4rem;
  font-family: "Courier New", Courier, monospace;
  color: whitesmoke;
}

.call-to-action {
  color: whitesmoke;
  animation: blink 3s ease-in-out infinite, move 3s cubic-bezier(0.55, 0.01, 0.46, 0.99) infinite;
}

.time-selection {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.time-selection .time-value {
  color: white;
  padding: 0.25rem;
  margin-left: 1rem;
  margin-right: 1rem;
}
.time-selection .time-value:hover {
  cursor: pointer;
}

@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
}

@keyframes move {
  0%,
  100% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
  }
}

.mobile {
  display: none;
}

@media only screen and (max-width: 911px) {
  * {
    padding: 0;
    margin: 0;
  }
  .content {
    display: none;
  }
  .mobile {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    background-color: rgb(29, 31, 36);
    color: whitesmoke;
  }
}
